import { Menu } from '@/components/common/Menu'
import Logo from '@/components/common/Logo'
import { usePinable } from '@/hooks/Pinable'
import styles from '@/styles/components/common/Navbar.module.scss'
import { useQuery } from '@/utils/url'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'

const DynamicMobileMenuOffcanvas5 = dynamic( // @ts-ignore
  () => import('@/components/variants/components/MobileMenuOffcanvas5').then((mod) => mod.MobileMenuOffcanvas5),
  { ssr: false }
)

type Props = {
  pinOffset?: number,
  forceSmallContainer?: boolean,
  disablePin?: boolean,
  darkLogo?: boolean,
  darkSubmenu?: boolean,
  darkMenu?: boolean,
  lightBg?: boolean,
  logoLink?: string
}

export const NavbarWithSubmenu: React.FC<Props> = ({pinOffset = 30, forceSmallContainer = false, disablePin = false, darkLogo = false, darkMenu = false, darkSubmenu = true, lightBg = false, logoLink = ''}) => {
  const isPinned = usePinable(pinOffset)
  const [show, setShow] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isHome, setIsHome] = useState(false)

  const pinnedClass = () => {
    if (isPinned && !disablePin) {
      return `${styles.navbar__pinned} ${lightBg ?  styles.navbar__pinned_light : null}`
    }

    return ''
  }

  const closeHandler = () => {
    setShow(false)
  }

  const showHandler = () => {
    setLoaded(true)
    setShow(true)
  }

  useEffect(() => {
    setIsHome(location.pathname === '/' || location.pathname === '/lp/v02/' || location.pathname === '/lp/v01/')
  })

  return (
    <div className={`${styles.navbar} ${pinnedClass()}`}>
      <div className={`${styles.container} ${isHome || forceSmallContainer ? 'container--sec container--max-w-1111' : ''} container flex justify-between`}>
        <Logo className={`${styles.logo} logo--primary`} link={logoLink || undefined} color={darkLogo ? 'black' : 'white' }/>
        <div id="nav-primary" className="nav__wrapper nav__wrapper--mini">
          <nav>
            <ul className="nav nav--primary text-[10px] 2xl:text-[13px] xxl:text-[14px]">
              <li className="nav__item nav__item--primary nav--submenu-hover hover-underline-animation nav__item--margin hidden xl:block">
                <div className='flex items-center'>
                  Learn
                  <span className={`chevron ${darkSubmenu ? '' : 'chevron--black'} ml-[5px]`}></span>
                </div>
                <div className='absolute submenu-anim'>
                  <ul className={`p-[20px] ml-[-20px] rounded-[8px] mt-[10px] ${darkSubmenu ? 'bg--dark-blue' : ''}  shadow-xl`}>
                    <li className='inline-block cursor-pointer mb-[10px] hover-underline-animation whitespace-nowrap'><Link href={useQuery('/lp/course')} passHref>Scientific Productivity Course</Link></li>
                    <li className='inline-block cursor-pointer hover-underline-animation'><Link href={useQuery('/post')} passHref>Blog</Link></li>
                  </ul>
                </div>
              </li>
              <li className="nav__item nav__item--primary hover-underline-animation nav__item--margin hidden xl:block">
                <Link href={useQuery('/see-the-science')} passHref>See the science</Link>
              </li>
              <li className="nav__item nav__item--primary hover-underline-animation nav__item--margin hidden xl:block">
                <Link href={useQuery('/teams')} passHref>Teams</Link>
              </li>
              <li className="nav__item nav__item--primary hover-underline-animation nav__item--margin hidden xl:block">
                <Link href={useQuery('/success-stories')} passHref>Success stories</Link>
              </li>
              <li className="nav__item nav__item--primary hover-underline-animation nav__item--margin hidden xl:block">
                <Link href={useQuery('/about')} passHref>About</Link>
              </li>
              <li className="nav__item nav__item--primary hover-underline-animation nav__item--margin hidden xl:block">
                <Link href={useQuery('/contact')} passHref>Contact us</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className='flex items-center'>
          <Menu withText={true} alwaysVisible={true}  menuColor={darkMenu ? 'black' : 'white'} smallMenu={true} customCallback={() => {
            showHandler()
          }}/>
        </div>
        {loaded && <DynamicMobileMenuOffcanvas5 show={show} close={closeHandler} />}
      </div>
    </div>
  )
}
